h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.25rem;
}

.page-1 {
  color: #ffffff;
  min-height: 100vh;
  width: 100%;
  padding: 2rem;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(246, 97, 122, 1) 0%,
    rgba(198, 226, 66, 1) 100%
  );
}
.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.nav-link {
  font-size: 1.5rem;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
}

.quote-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 4em;
}

.quote {
  width: 500px;
  font-size: 4rem;
}

.page-1 iframe {
  width: 550px;
  height: 100%;
}
.video {
  align-self: center;
  width: 550px;
  height: 475px;
}
img.karen-speaks {
  width: 350px;
  height: 350px;
  transform: scale(1.75, 1.25);
}

.book-karen {
  text-align: center;
  width: 100%;
  padding-bottom: 4rem;
}

button.book {
  background-color: rgba(198, 226, 66, 1);
  color: black;
  border: none;
  padding: 12px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 40px;
  cursor: pointer;
  position: fixed;
  bottom: 30px;
  left: 45%;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
}

.page-2 {
  background-color: #e0e0e0;
  width: 100%;
  padding: 2rem;
}

.page-2 .image-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.page-2 img {
  height: 300px;
  max-width: 300px;
  display: block;
  padding: 2rem;
  border-radius: 20%;
}

.page-2 h2 {
  text-align: center;
  color: black;
}

.page-3 {
  min-height: 100vh;
  background-color: #ffffff;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
}

.page-3 h2 {
  text-align: center;
  color: rgba(246, 97, 122, 1);
  margin: 0;
}

.page-3 iframe {
  width: 80%;
  height: 550px;
  margin-top: 4em;
  margin-left: auto;
  margin-right: auto;
}

.page-4 {
  min-height: 100vh;
  background-color: #ffffff;
  width: 100%;
  padding: 2rem;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(198, 226, 66, 1) 0%,
    rgba(246, 97, 122, 1) 100%
  );
}

.page-4 .testimonial-header {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.page-4 h3 {
  color: #ffffff;
  font-weight: bold;
  margin-top: 70px;
  font-size: 40px;
}

.page-4 img.karen-speaks {
  width: 250px;
  height: 200px;
}

.page-4 .testimonials {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 36px;
}

.page-4 .testimonial {
  width: 350px;
  font-size: 2em;
  color: #ffffff;
  border: 4px solid #ffffff;
  border-radius: 25px;
  padding: 15px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.page-4 .testimonial p {
  padding: 0;
  margin: 0;
  font-weight: bold;
}

.page-6 {
  min-height: 100vh;
  background-color: #ffffff;
  width: 100%;
  padding: 2rem;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(198, 226, 66, 1) 0%,
    rgba(246, 97, 122, 1) 100%
  );
  color: #ffffff;
  font-weight: bold;
}

.page-6 h2 {
  text-align: center;
}

.page-6 h3 {
  color: rgb(246, 76, 105);
  font-weight: bolder;
}

.page-6 p {
  color: black;
}

.page-6-container {
  font-size: 2em;
  color: #ffffff;
  border: 4px solid rgba(198, 226, 66, 1);
  border-radius: 25px;
  padding: 15px;
  margin: 12px;
  max-width: 1500px;
  margin: 24px auto;
}

@media (max-width: 1200px) {
  .nav-link {
    display: none;
  }
  .quote-container {
    flex-direction: column;
  }
  .karen-speaks {
    width: 100%;
  }
  .page-1 {
    padding: 0;
  }
  h2 {
    font-size: 1.5rem;
  }
  .quote {
    font-size: 3rem;
    width: 100%;
    text-align: center;
  }

  iframe {
    width: 50%;
    margin: 0 25%;
    height: 250px;
  }

  .book-karen h2 {
    display: none;
  }

  img.karen-speaks {
    width: 250px;
    height: 250px;
    display: block;
    margin: auto;
    /* transform: scale(2); */
  }
}
